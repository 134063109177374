import React, { Suspense } from "react";

import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

//Default Layout
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

//Routes go here
// import Home from './pages/Home';
// import OurStory from "./pages/OurStory";
// import OurProducts from "./pages/OurProducts";
// import OurKitchen from "./pages/OurKitchen";
// import OurStoreLocator from "./pages/OurStoreLocator";
// import Media from "./pages/Media";
// import Blog from "./pages/Blog";
// import BlogCardDetails from "./components/BlogCardDetails";
// import Contact from "./pages/Contact";
import Spinner from './assets/Spinner.svg';

//mostly common stlyes & each component css here
import "./App.scss";

//Code splitting for performance
const Home = React.lazy(() => import("./pages/Home"));
const OurStory = React.lazy(() => import("./pages/OurStory"));
const OurProducts = React.lazy(() => import("./pages/OurProducts"));
const OurKitchen = React.lazy(() => import("./pages/OurKitchen"));
const OurStoreLocator = React.lazy(() => import("./pages/OurStoreLocator"));
const Media = React.lazy(() => import("./pages/Media"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogCardDetails = React.lazy(() =>
  import("./components/BlogCardDetails")
);
const Contact = React.lazy(() => import("./pages/Contact"));

//Main Component entry for routes
function App() {
  return (
    
      <div className="App">
      <Suspense fallback={<div className="Spinner"><img src={Spinner} alt="spinner"/></div>}>
        <NavigationBar />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/story" component={OurStory}></Route>
          <Route exact path="/products" component={OurProducts}></Route>
          <Route exact path="/kitchen" component={OurKitchen}></Route>
          <Route exact path="/locator" component={OurStoreLocator}></Route>
          <Route exact path="/media" component={Media}></Route>
          <Route exact path="/blogs" component={Blog}></Route>
          <Route exact path="/blogs/:id" component={BlogCardDetails}></Route>
          <Route exact path="/contact" component={Contact}></Route>
        </Switch>
        <Footer />
        </Suspense>
      </div>

  );
}

export default App;
