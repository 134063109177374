import React, { useState, useEffect } from "react";

// import useDimensions from "react-use-dimensions";
// import Navbar from "react-bootstrap/Navbar";
import { Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Hamburger from "../assets/hamburger.svg";
import Cross from "../assets/cross.svg";

const NavigationBar = () => {
  //state for togglign navbar
  const [clicked, setClicked] = useState(false);

  //method
  const handleClick = () => {
    // e.preventDefault();
    setClicked(!clicked);
  };


  const { height, width } = useWindowDimensions();
  useEffect(() => {
    console.log("height :", height, "width :", width);
  }, [height, width])

  

  //useDimernsions for vertical cards on mobile
  // const [widthRef, navSize] = useDimensions({liveMeasure: true});
  // console.log("navbar is", navSize.width, "wide");
  // ref={widthRef}
  return (
    <Container className="px-0 MainNavBar shadow-lg" fluid >
      <nav className="NavbarItems ">
        <div className="menu-icon  order-first" onClick={handleClick}>
          <div>
            <img
              src={clicked ? Cross : Hamburger}
              className="menu-icon-image"
              alt="menu icon"
              height="48px"
            />
          </div>
        </div>
        <div className="navbar-logo  order-second">
          <LinkContainer to="/">
            <img
              src={process.env.PUBLIC_URL + "/images/logo.svg"}
              alt="brand-logo"
              className="navbar-brand-logo"
            />
          </LinkContainer>
        </div>

        <ul className={clicked && width < 1400 ? "nav-menu active" : "nav-menu"}>
          <LinkContainer to="/story" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">LAXMI'S STORY</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/products" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">OUR PRODUCTS</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/locator" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">STORE LOCATOR</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/kitchen" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">LAXMI'S KITCHEN</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/media" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">MEDIA</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/blogs" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">BLOGS</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/contact" onClick={handleClick}>
            <Nav.Link className="f-HR-20 nav-links">CONTACT US</Nav.Link>
          </LinkContainer>
        </ul>
      </nav>
    </Container>
  );
};


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default NavigationBar;

// <Container fluid className="nav-container px-0">
// <Navbar expand="xxl"  className="shadow-lg w-100 px-5 MainNavBar">
// <Navbar.Toggle aria-controls="basic-navbar-nav" />
//   <LinkContainer to="/">
//     <Navbar.Brand>
//       <img
//         src={process.env.PUBLIC_URL + "/images/logo.svg"}
//         alt="brand-logo"
//         className="navbar-brand-logo"
//       />
//     </Navbar.Brand>
//   </LinkContainer>

//   <Navbar.Collapse id="basic-navbar-nav" className="justify-content-md-end">
//     <Nav className="mr-auto">
//       <LinkContainer to="/story">
//         <Nav.Link className="f-HR-20">LAXMI'S STORY</Nav.Link>
//       </LinkContainer>
//       <LinkContainer to="/products">
//         <Nav.Link className="f-HR-20">OUR PRODUCTS</Nav.Link>
//       </LinkContainer>
//       <LinkContainer to="/locator">
//         <Nav.Link className="f-HR-20">STORE LOCATOR</Nav.Link>
//       </LinkContainer>
//       <LinkContainer to="/kitchen">
//         <Nav.Link className="f-HR-20">LAXMI'S KITCHEN</Nav.Link>
//       </LinkContainer>
//       <LinkContainer to="/media">
//         <Nav.Link className="f-HR-20">MEDIA</Nav.Link>
//       </LinkContainer>
//       <LinkContainer to="/blogs">
//         <Nav.Link className="f-HR-20">BLOGS</Nav.Link>
//       </LinkContainer>
//       <LinkContainer to="/contact">
//         <Nav.Link className="f-HR-20">CONTACT US</Nav.Link>
//       </LinkContainer>
//     </Nav>
//   </Navbar.Collapse>
// </Navbar>
// </Container>
