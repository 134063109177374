import React from "react";

import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <Container fluid className="CyanBg MainFooter">
      <Row>
        <Col className="mobile-left-leaf px-0">
          <img
            src={process.env.PUBLIC_URL + "/images/white-leaf.png"}
            alt="whiteLeaf"
            className="left-leaf"
          />
        </Col>
        <Col  className=" socialMedia">
          <Row>
            <div className="emailPhoneContainer">
              <div className="emailCol my-2 my-md-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/emailIcon.svg"}
                  alt="Email"
                  className="emailIcon"
                />
                <div className="px-2  emailIdCol">
                  <span>Email id</span>
                  <span>xyz@gmail.com</span>
                </div>
              </div>
              <div className="phoneCol my-2 my-md-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/emailIcon.svg"}
                  alt="Email"
                  className="phoneIcon"
                />
                <div className="px-2 phoneNumberCol">
                  <span>Phone No.</span>
                  <span>xyz@gmail.com</span>
                </div>
              </div>
            </div>
            <div className="socialLinksContainer">
              <div className="socialLinksItem">
                <img
                  src={process.env.PUBLIC_URL + "/images/instagram.svg"}
                  alt="whiteLeaf"
                  className="footerIcon"
                />
              </div>
              <div className="socialLinksItem">
                <img
                  src={process.env.PUBLIC_URL + "/images/youtube.svg"}
                  alt="whiteLeaf"
                  className="footerIcon"
                />
              </div>
              <div className="socialLinksItem">
                <img
                  src={process.env.PUBLIC_URL + "/images/linkedin.svg"}
                  alt="whiteLeaf"
                  className="footerIcon"
                />
              </div>
              <div className="socialLinksItem">
                <img
                  src={process.env.PUBLIC_URL + "/images/facebook.svg"}
                  alt="whiteLeaf"
                  className="footerIcon"
                />
              </div>
            </div>
            <div className="policyContainer">
              <span className="refLinks">Terms and Conditions</span>
              <span className="footerBar">|</span>
              <span className="refLinks">Privacy Policy</span>
              <span className="footerBar">|</span>
              <span className="refLinks">Copyrights Reserved @Laxmi</span>
              <span className="footerBar">|</span>
              <span className="refLinks">Crafted by @Togglehead.in</span>
            </div>
          </Row>
        </Col>
        <Col  className="mobile-right-leaf px-0">
          <img
            src={process.env.PUBLIC_URL + "/images/right-leaf.png"}
            alt="whiteLeaf"
            className="right-leaf"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;

// <img
// src={process.env.PUBLIC_URL + "/images/white-leaf.png"}
// alt="whiteLeaf"
// className="btm-left-leaf"
// />
// <img
// src={process.env.PUBLIC_URL + "/images/right-leaf.png"}
// alt="whiteLeaf"
// className="btm-right-leaf"
// />